/*eslint-env node*/
exports.linkResolver = function linkResolver (doc) {
  if (doc.type === 'career_position') {
    return `/careers/${doc.slug}`;
  }

  if (doc.type === 'article') {
    return `/news/${doc.slug}`;
  }

  if (doc.slug === 'home' && doc.type === 'page') {
    return '/';
  }

  if (doc.slug) {
    return `/${doc.slug}`;
  }

  throw new Error(`Invalid type for link: ${JSON.stringify(doc)}`);
};
