/*globals process*/
import * as React from 'react';
import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews';

// Update the path to your Link Resolver
import { linkResolver } from '../utils/linkResolver';

const PreviewPage = () => {
  return ( <p>Loading...</p> );
};

export default withPrismicPreviewResolver(PreviewPage, [
  {
    repositoryName: process.env.PRISMIC_REPOSITORY,
    linkResolver
  }
]);
